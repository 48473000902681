
.votingResults {
  width: 50%;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 3em 0;
  color: #001c62;
  background-color: #001c62;
  border: 1px solid black;
  opacity: 0.9;
  border: 1px solid #f59e0a;
  border-radius: 10px;
}
.votingResult {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}
.letter {
  margin: 0.5em 0;
}
.percentageDiagram {
  transition: height 3s;
  background-color: #f59e0a;
  height: 0;
  width: 70%;
  border: 1px solid black;
}
