.line {
  
  color: white;
  /*  top: 50%;*/
  margin: 16px auto;
  border-right: 2px solid rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

/*credit : https://css-tricks.com/snippets/css/typewriter-effect/*/
/* Animation */
.anim-typewriter {
  animation: typewriter 4s steps(44) 2s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 24em;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

/* .jokersModal{
  top:0;
  -webkit-animation: move-in-steps 1.5s;
          animation: move-in-steps 1.5s;
}
@-webkit-keyframes move-in-steps {
  0% {
    left: -100%;

  }
  100% {
    left: 0%;
  }
}

@keyframes move-in-steps {
  0% {
    left: -100%;

  }
  100% {
    left: 0%;
  }
}
.jokersModal.opened{
  -webkit-animation: move-in-steps-opened 1s;
          animation: move-in-steps-opened 1s;
}
@-webkit-keyframes move-in-steps-opened {
  0% {
    left: 0%;

  }
  100% {
    left: 100%;
  }
}

@keyframes move-in-steps-opened {
  0% {
    left: 0%;

  }
  100% {
    left: -100%;
  }
} */

.jokersModal-enter-active{
  -webkit-animation: jokers-move-in-steps 0.9s;
  animation:jokers-move-in-steps 0.9s;
}
@-webkit-keyframes jokers-move-in-steps {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

@keyframes jokers-move-in-steps {
  0% {
    left: -100%;

  }
  100% {
    left: 0%;
  }
}

.jokersModal-exit-active{
  -webkit-animation: jokers-move-in-steps-opened 1s;
  animation: jokers-move-in-steps-opened 1s;
}
@-webkit-keyframes jokers-move-in-steps-opened {
  0% {
    left: 0%;

  }
  100% {
    left: 100%;
  }
}

@keyframes jokers-move-in-steps-opened {
  0% {
    left: 0%;

  }
  100% {
    left: -100%;
  }
}
.prizesModal-enter-active{
  -webkit-animation: prizes-move-in-steps 1s;
          animation: prizes-move-in-steps 1s;
}
@-webkit-keyframes prizes-move-in-steps {
  0% {
    right: -100%;
  }
  100% {
    right: 0%;
  }
}

@keyframes prizes-move-in-steps {
  0% {
    right: -100%;

  }
  100% {
    right: 0%;
  }
}
.prizesModal-exit-active{
  -webkit-animation: prizes-move-in-steps-opened 1s;
          animation:prizes-move-in-steps-opened 1s;
}
@keyframes prizes-move-in-steps-opened {
  0% {
    left: 0%;

  }
  100% {
    left: 100%;
  }
}
@-webkit-keyframes prizes-move-in-steps-opened {
  0% {
    left: 0%;

  }
  100% {
    left: 100%;
  }
}


[class|=confetti] {
  position: absolute;
}

.confetti-0 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 29%;
  opacity: 1.0534264009;
  transform: rotate(45.7923486923deg);
  -webkit-animation: drop-0 4.0733925998s 0.8568651569s infinite;
          animation: drop-0 4.0733925998s 0.8568651569s infinite;
}

@-webkit-keyframes drop-0 {
  100% {
    top: 110%;
    left: 38%;
  }
}

@keyframes drop-0 {
  100% {
    top: 110%;
    left: 38%;
  }
}
.confetti-1 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 96%;
  opacity: 0.9537226799;
  transform: rotate(320.8898019147deg);
  -webkit-animation: drop-1 4.9844472912s 0.8690746754s infinite;
          animation: drop-1 4.9844472912s 0.8690746754s infinite;
}

@-webkit-keyframes drop-1 {
  100% {
    top: 110%;
    left: 100%;
  }
}

@keyframes drop-1 {
  100% {
    top: 110%;
    left: 100%;
  }
}
.confetti-2 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 9%;
  opacity: 1.2854146786;
  transform: rotate(216.1949016215deg);
  -webkit-animation: drop-2 4.977692326s 0.7822492922s infinite;
          animation: drop-2 4.977692326s 0.7822492922s infinite;
}

@-webkit-keyframes drop-2 {
  100% {
    top: 110%;
    left: 16%;
  }
}

@keyframes drop-2 {
  100% {
    top: 110%;
    left: 16%;
  }
}
.confetti-3 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 42%;
  opacity: 1.3616012342;
  transform: rotate(316.4350657676deg);
  -webkit-animation: drop-3 4.1644281258s 0.2058740853s infinite;
          animation: drop-3 4.1644281258s 0.2058740853s infinite;
}

@-webkit-keyframes drop-3 {
  100% {
    top: 110%;
    left: 43%;
  }
}

@keyframes drop-3 {
  100% {
    top: 110%;
    left: 43%;
  }
}
.confetti-4 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 7%;
  opacity: 1.2993168734;
  transform: rotate(121.5169210977deg);
  -webkit-animation: drop-4 4.7508197254s 0.3921343024s infinite;
          animation: drop-4 4.7508197254s 0.3921343024s infinite;
}

@-webkit-keyframes drop-4 {
  100% {
    top: 110%;
    left: 19%;
  }
}

@keyframes drop-4 {
  100% {
    top: 110%;
    left: 19%;
  }
}
.confetti-5 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 24%;
  opacity: 1.1374649937;
  transform: rotate(289.6882966896deg);
  -webkit-animation: drop-5 4.8451393495s 0.5331258318s infinite;
          animation: drop-5 4.8451393495s 0.5331258318s infinite;
}

@-webkit-keyframes drop-5 {
  100% {
    top: 110%;
    left: 34%;
  }
}

@keyframes drop-5 {
  100% {
    top: 110%;
    left: 34%;
  }
}
.confetti-6 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 24%;
  opacity: 0.8463989804;
  transform: rotate(162.6819269481deg);
  -webkit-animation: drop-6 4.3502075014s 0.718050315s infinite;
          animation: drop-6 4.3502075014s 0.718050315s infinite;
}

@-webkit-keyframes drop-6 {
  100% {
    top: 110%;
    left: 31%;
  }
}

@keyframes drop-6 {
  100% {
    top: 110%;
    left: 31%;
  }
}
.confetti-7 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 53%;
  opacity: 1.0581792292;
  transform: rotate(271.6753014233deg);
  -webkit-animation: drop-7 4.7292878578s 0.2574136529s infinite;
          animation: drop-7 4.7292878578s 0.2574136529s infinite;
}

@-webkit-keyframes drop-7 {
  100% {
    top: 110%;
    left: 54%;
  }
}

@keyframes drop-7 {
  100% {
    top: 110%;
    left: 54%;
  }
}
.confetti-8 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 3%;
  opacity: 1.2051752155;
  transform: rotate(165.5168203088deg);
  -webkit-animation: drop-8 4.6762335804s 0.6190823646s infinite;
          animation: drop-8 4.6762335804s 0.6190823646s infinite;
}

@-webkit-keyframes drop-8 {
  100% {
    top: 110%;
    left: 10%;
  }
}

@keyframes drop-8 {
  100% {
    top: 110%;
    left: 10%;
  }
}
.confetti-9 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 80%;
  opacity: 1.0451802875;
  transform: rotate(317.6558657151deg);
  -webkit-animation: drop-9 4.4720913259s 0.6163216853s infinite;
          animation: drop-9 4.4720913259s 0.6163216853s infinite;
}

@-webkit-keyframes drop-9 {
  100% {
    top: 110%;
    left: 86%;
  }
}

@keyframes drop-9 {
  100% {
    top: 110%;
    left: 86%;
  }
}
.confetti-10 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 69%;
  opacity: 1.1229630574;
  transform: rotate(164.6825611263deg);
  -webkit-animation: drop-10 4.44076438s 0.650574641s infinite;
          animation: drop-10 4.44076438s 0.650574641s infinite;
}

@-webkit-keyframes drop-10 {
  100% {
    top: 110%;
    left: 83%;
  }
}

@keyframes drop-10 {
  100% {
    top: 110%;
    left: 83%;
  }
}
.confetti-11 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 40%;
  opacity: 0.9562310186;
  transform: rotate(263.9266817269deg);
  -webkit-animation: drop-11 4.571909471s 0.1574654586s infinite;
          animation: drop-11 4.571909471s 0.1574654586s infinite;
}

@-webkit-keyframes drop-11 {
  100% {
    top: 110%;
    left: 48%;
  }
}

@keyframes drop-11 {
  100% {
    top: 110%;
    left: 48%;
  }
}
.confetti-12 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 7%;
  opacity: 0.814259648;
  transform: rotate(268.9501561677deg);
  -webkit-animation: drop-12 4.6453608619s 0.2079023343s infinite;
          animation: drop-12 4.6453608619s 0.2079023343s infinite;
}

@-webkit-keyframes drop-12 {
  100% {
    top: 110%;
    left: 9%;
  }
}

@keyframes drop-12 {
  100% {
    top: 110%;
    left: 9%;
  }
}
.confetti-13 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 90%;
  opacity: 1.4189415749;
  transform: rotate(17.8751201256deg);
  -webkit-animation: drop-13 4.4421606766s 0.3060699943s infinite;
          animation: drop-13 4.4421606766s 0.3060699943s infinite;
}

@-webkit-keyframes drop-13 {
  100% {
    top: 110%;
    left: 96%;
  }
}

@keyframes drop-13 {
  100% {
    top: 110%;
    left: 96%;
  }
}
.confetti-14 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 54%;
  opacity: 0.7426120673;
  transform: rotate(205.402061024deg);
  -webkit-animation: drop-14 4.1096955166s 0.7627547147s infinite;
          animation: drop-14 4.1096955166s 0.7627547147s infinite;
}

@-webkit-keyframes drop-14 {
  100% {
    top: 110%;
    left: 67%;
  }
}

@keyframes drop-14 {
  100% {
    top: 110%;
    left: 67%;
  }
}
.confetti-15 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 9%;
  opacity: 1.1256648978;
  transform: rotate(275.5396726582deg);
  -webkit-animation: drop-15 4.6371075813s 0.3410771153s infinite;
          animation: drop-15 4.6371075813s 0.3410771153s infinite;
}

@-webkit-keyframes drop-15 {
  100% {
    top: 110%;
    left: 21%;
  }
}

@keyframes drop-15 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.confetti-16 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 89%;
  opacity: 0.657087173;
  transform: rotate(277.7525607439deg);
  -webkit-animation: drop-16 4.6087830618s 0.2227682357s infinite;
          animation: drop-16 4.6087830618s 0.2227682357s infinite;
}

@-webkit-keyframes drop-16 {
  100% {
    top: 110%;
    left: 92%;
  }
}

@keyframes drop-16 {
  100% {
    top: 110%;
    left: 92%;
  }
}
.confetti-17 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 24%;
  opacity: 0.9167574258;
  transform: rotate(4.9771802198deg);
  -webkit-animation: drop-17 4.0849323853s 0.7978284182s infinite;
          animation: drop-17 4.0849323853s 0.7978284182s infinite;
}

@-webkit-keyframes drop-17 {
  100% {
    top: 110%;
    left: 36%;
  }
}

@keyframes drop-17 {
  100% {
    top: 110%;
    left: 36%;
  }
}
.confetti-18 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 31%;
  opacity: 1.3006626242;
  transform: rotate(337.946441726deg);
  -webkit-animation: drop-18 4.2805073836s 0.5783208774s infinite;
          animation: drop-18 4.2805073836s 0.5783208774s infinite;
}

@-webkit-keyframes drop-18 {
  100% {
    top: 110%;
    left: 42%;
  }
}

@keyframes drop-18 {
  100% {
    top: 110%;
    left: 42%;
  }
}
.confetti-19 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 29%;
  opacity: 0.794180107;
  transform: rotate(298.9475541907deg);
  -webkit-animation: drop-19 4.4113924459s 0.9267956393s infinite;
          animation: drop-19 4.4113924459s 0.9267956393s infinite;
}

@-webkit-keyframes drop-19 {
  100% {
    top: 110%;
    left: 31%;
  }
}

@keyframes drop-19 {
  100% {
    top: 110%;
    left: 31%;
  }
}
.confetti-20 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 83%;
  opacity: 1.2307703797;
  transform: rotate(238.4252144504deg);
  -webkit-animation: drop-20 4.7472741221s 0.0174388973s infinite;
          animation: drop-20 4.7472741221s 0.0174388973s infinite;
}

@-webkit-keyframes drop-20 {
  100% {
    top: 110%;
    left: 95%;
  }
}

@keyframes drop-20 {
  100% {
    top: 110%;
    left: 95%;
  }
}
.confetti-21 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 29%;
  opacity: 1.2197583809;
  transform: rotate(300.7806552449deg);
  -webkit-animation: drop-21 4.2465987355s 0.9997367096s infinite;
          animation: drop-21 4.2465987355s 0.9997367096s infinite;
}

@-webkit-keyframes drop-21 {
  100% {
    top: 110%;
    left: 43%;
  }
}

@keyframes drop-21 {
  100% {
    top: 110%;
    left: 43%;
  }
}
.confetti-22 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 11%;
  opacity: 1.4116549757;
  transform: rotate(16.9573332735deg);
  -webkit-animation: drop-22 4.1639296769s 0.1935151911s infinite;
          animation: drop-22 4.1639296769s 0.1935151911s infinite;
}

@-webkit-keyframes drop-22 {
  100% {
    top: 110%;
    left: 24%;
  }
}

@keyframes drop-22 {
  100% {
    top: 110%;
    left: 24%;
  }
}
.confetti-23 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 98%;
  opacity: 1.0594995545;
  transform: rotate(348.4544814894deg);
  -webkit-animation: drop-23 4.4367832053s 0.3651687826s infinite;
          animation: drop-23 4.4367832053s 0.3651687826s infinite;
}

@-webkit-keyframes drop-23 {
  100% {
    top: 110%;
    left: 102%;
  }
}

@keyframes drop-23 {
  100% {
    top: 110%;
    left: 102%;
  }
}
.confetti-24 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 7%;
  opacity: 1.3883363565;
  transform: rotate(238.3143801029deg);
  -webkit-animation: drop-24 4.7034491291s 0.9588737377s infinite;
          animation: drop-24 4.7034491291s 0.9588737377s infinite;
}

@-webkit-keyframes drop-24 {
  100% {
    top: 110%;
    left: 13%;
  }
}

@keyframes drop-24 {
  100% {
    top: 110%;
    left: 13%;
  }
}
.confetti-25 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 16%;
  opacity: 0.9612900379;
  transform: rotate(124.3229006401deg);
  -webkit-animation: drop-25 4.2101516853s 0.2956928192s infinite;
          animation: drop-25 4.2101516853s 0.2956928192s infinite;
}

@-webkit-keyframes drop-25 {
  100% {
    top: 110%;
    left: 31%;
  }
}

@keyframes drop-25 {
  100% {
    top: 110%;
    left: 31%;
  }
}
.confetti-26 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 81%;
  opacity: 1.1174298125;
  transform: rotate(145.4910696665deg);
  -webkit-animation: drop-26 4.4805709731s 0.1981392125s infinite;
          animation: drop-26 4.4805709731s 0.1981392125s infinite;
}

@-webkit-keyframes drop-26 {
  100% {
    top: 110%;
    left: 82%;
  }
}

@keyframes drop-26 {
  100% {
    top: 110%;
    left: 82%;
  }
}
.confetti-27 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 50%;
  opacity: 0.5054173084;
  transform: rotate(134.1776315502deg);
  -webkit-animation: drop-27 4.2068864605s 0.0384690027s infinite;
          animation: drop-27 4.2068864605s 0.0384690027s infinite;
}

@-webkit-keyframes drop-27 {
  100% {
    top: 110%;
    left: 64%;
  }
}

@keyframes drop-27 {
  100% {
    top: 110%;
    left: 64%;
  }
}
.confetti-28 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 54%;
  opacity: 0.5082611087;
  transform: rotate(1.0895906201deg);
  -webkit-animation: drop-28 4.621056987s 0.5026037835s infinite;
          animation: drop-28 4.621056987s 0.5026037835s infinite;
}

@-webkit-keyframes drop-28 {
  100% {
    top: 110%;
    left: 62%;
  }
}

@keyframes drop-28 {
  100% {
    top: 110%;
    left: 62%;
  }
}
.confetti-29 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 22%;
  opacity: 1.3049376444;
  transform: rotate(63.7172171538deg);
  -webkit-animation: drop-29 4.5189890151s 0.4089504188s infinite;
          animation: drop-29 4.5189890151s 0.4089504188s infinite;
}

@-webkit-keyframes drop-29 {
  100% {
    top: 110%;
    left: 35%;
  }
}

@keyframes drop-29 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.confetti-30 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 100%;
  opacity: 0.8104554548;
  transform: rotate(216.1936441341deg);
  -webkit-animation: drop-30 4.4218912994s 0.7811384435s infinite;
          animation: drop-30 4.4218912994s 0.7811384435s infinite;
}

@-webkit-keyframes drop-30 {
  100% {
    top: 110%;
    left: 111%;
  }
}

@keyframes drop-30 {
  100% {
    top: 110%;
    left: 111%;
  }
}
.confetti-31 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 73%;
  opacity: 0.6162492915;
  transform: rotate(21.4816359653deg);
  -webkit-animation: drop-31 4.3880311781s 0.7569083785s infinite;
          animation: drop-31 4.3880311781s 0.7569083785s infinite;
}

@-webkit-keyframes drop-31 {
  100% {
    top: 110%;
    left: 84%;
  }
}

@keyframes drop-31 {
  100% {
    top: 110%;
    left: 84%;
  }
}
.confetti-32 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 73%;
  opacity: 1.393162613;
  transform: rotate(207.5339366558deg);
  -webkit-animation: drop-32 4.4471156622s 0.5555414482s infinite;
          animation: drop-32 4.4471156622s 0.5555414482s infinite;
}

@-webkit-keyframes drop-32 {
  100% {
    top: 110%;
    left: 88%;
  }
}

@keyframes drop-32 {
  100% {
    top: 110%;
    left: 88%;
  }
}
.confetti-33 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 40%;
  opacity: 0.8867885457;
  transform: rotate(126.0404651689deg);
  -webkit-animation: drop-33 4.4887717103s 0.0607031784s infinite;
          animation: drop-33 4.4887717103s 0.0607031784s infinite;
}

@-webkit-keyframes drop-33 {
  100% {
    top: 110%;
    left: 47%;
  }
}

@keyframes drop-33 {
  100% {
    top: 110%;
    left: 47%;
  }
}
.confetti-34 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 28%;
  opacity: 0.9979032717;
  transform: rotate(135.458630558deg);
  -webkit-animation: drop-34 4.5887793943s 0.3981767385s infinite;
          animation: drop-34 4.5887793943s 0.3981767385s infinite;
}

@-webkit-keyframes drop-34 {
  100% {
    top: 110%;
    left: 40%;
  }
}

@keyframes drop-34 {
  100% {
    top: 110%;
    left: 40%;
  }
}
.confetti-35 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 14%;
  opacity: 0.6562513113;
  transform: rotate(27.9921232038deg);
  -webkit-animation: drop-35 4.3729581109s 0.9542973902s infinite;
          animation: drop-35 4.3729581109s 0.9542973902s infinite;
}

@-webkit-keyframes drop-35 {
  100% {
    top: 110%;
    left: 19%;
  }
}

@keyframes drop-35 {
  100% {
    top: 110%;
    left: 19%;
  }
}
.confetti-36 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 79%;
  opacity: 0.7654235152;
  transform: rotate(145.4619325989deg);
  -webkit-animation: drop-36 4.0276782678s 0.299440524s infinite;
          animation: drop-36 4.0276782678s 0.299440524s infinite;
}

@-webkit-keyframes drop-36 {
  100% {
    top: 110%;
    left: 81%;
  }
}

@keyframes drop-36 {
  100% {
    top: 110%;
    left: 81%;
  }
}
.confetti-37 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 70%;
  opacity: 0.713395588;
  transform: rotate(238.4963159716deg);
  -webkit-animation: drop-37 4.1627540246s 0.3943291776s infinite;
          animation: drop-37 4.1627540246s 0.3943291776s infinite;
}

@-webkit-keyframes drop-37 {
  100% {
    top: 110%;
    left: 82%;
  }
}

@keyframes drop-37 {
  100% {
    top: 110%;
    left: 82%;
  }
}
.confetti-38 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 93%;
  opacity: 1.0748202794;
  transform: rotate(75.7368257473deg);
  -webkit-animation: drop-38 4.6861271902s 0.0142490388s infinite;
          animation: drop-38 4.6861271902s 0.0142490388s infinite;
}

@-webkit-keyframes drop-38 {
  100% {
    top: 110%;
    left: 99%;
  }
}

@keyframes drop-38 {
  100% {
    top: 110%;
    left: 99%;
  }
}
.confetti-39 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 72%;
  opacity: 1.3621335358;
  transform: rotate(347.5363573999deg);
  -webkit-animation: drop-39 4.5850912027s 0.336866165s infinite;
          animation: drop-39 4.5850912027s 0.336866165s infinite;
}

@-webkit-keyframes drop-39 {
  100% {
    top: 110%;
    left: 73%;
  }
}

@keyframes drop-39 {
  100% {
    top: 110%;
    left: 73%;
  }
}
.confetti-40 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 76%;
  opacity: 0.7767905709;
  transform: rotate(336.2644757146deg);
  -webkit-animation: drop-40 4.4115215245s 0.412852399s infinite;
          animation: drop-40 4.4115215245s 0.412852399s infinite;
}

@-webkit-keyframes drop-40 {
  100% {
    top: 110%;
    left: 80%;
  }
}

@keyframes drop-40 {
  100% {
    top: 110%;
    left: 80%;
  }
}
.confetti-41 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 4%;
  opacity: 0.8472948346;
  transform: rotate(115.3241142135deg);
  -webkit-animation: drop-41 4.0965225782s 0.4086899916s infinite;
          animation: drop-41 4.0965225782s 0.4086899916s infinite;
}

@-webkit-keyframes drop-41 {
  100% {
    top: 110%;
    left: 8%;
  }
}

@keyframes drop-41 {
  100% {
    top: 110%;
    left: 8%;
  }
}
.confetti-42 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 5%;
  opacity: 0.6094277767;
  transform: rotate(95.6560049424deg);
  -webkit-animation: drop-42 4.0570178435s 0.8706217504s infinite;
          animation: drop-42 4.0570178435s 0.8706217504s infinite;
}

@-webkit-keyframes drop-42 {
  100% {
    top: 110%;
    left: 10%;
  }
}

@keyframes drop-42 {
  100% {
    top: 110%;
    left: 10%;
  }
}
.confetti-43 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 45%;
  opacity: 1.1965407643;
  transform: rotate(59.5205050802deg);
  -webkit-animation: drop-43 4.071068232s 0.5469255095s infinite;
          animation: drop-43 4.071068232s 0.5469255095s infinite;
}

@-webkit-keyframes drop-43 {
  100% {
    top: 110%;
    left: 58%;
  }
}

@keyframes drop-43 {
  100% {
    top: 110%;
    left: 58%;
  }
}
.confetti-44 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 35%;
  opacity: 0.6588425024;
  transform: rotate(203.3104789489deg);
  -webkit-animation: drop-44 4.0818498939s 0.1640508983s infinite;
          animation: drop-44 4.0818498939s 0.1640508983s infinite;
}

@-webkit-keyframes drop-44 {
  100% {
    top: 110%;
    left: 48%;
  }
}

@keyframes drop-44 {
  100% {
    top: 110%;
    left: 48%;
  }
}
.confetti-45 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 61%;
  opacity: 1.204347886;
  transform: rotate(231.6278639828deg);
  -webkit-animation: drop-45 4.6782960581s 0.5872730758s infinite;
          animation: drop-45 4.6782960581s 0.5872730758s infinite;
}

@-webkit-keyframes drop-45 {
  100% {
    top: 110%;
    left: 64%;
  }
}

@keyframes drop-45 {
  100% {
    top: 110%;
    left: 64%;
  }
}
.confetti-46 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 64%;
  opacity: 0.8338481028;
  transform: rotate(47.7967048219deg);
  -webkit-animation: drop-46 4.4467132123s 0.1827533829s infinite;
          animation: drop-46 4.4467132123s 0.1827533829s infinite;
}

@-webkit-keyframes drop-46 {
  100% {
    top: 110%;
    left: 65%;
  }
}

@keyframes drop-46 {
  100% {
    top: 110%;
    left: 65%;
  }
}
.confetti-47 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 89%;
  opacity: 1.4577386773;
  transform: rotate(3.5015503135deg);
  -webkit-animation: drop-47 4.1781308478s 0.2944206468s infinite;
          animation: drop-47 4.1781308478s 0.2944206468s infinite;
}

@-webkit-keyframes drop-47 {
  100% {
    top: 110%;
    left: 101%;
  }
}

@keyframes drop-47 {
  100% {
    top: 110%;
    left: 101%;
  }
}
.confetti-48 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 13%;
  opacity: 1.242361231;
  transform: rotate(217.5522695926deg);
  -webkit-animation: drop-48 4.2218800916s 0.3329900463s infinite;
          animation: drop-48 4.2218800916s 0.3329900463s infinite;
}

@-webkit-keyframes drop-48 {
  100% {
    top: 110%;
    left: 28%;
  }
}

@keyframes drop-48 {
  100% {
    top: 110%;
    left: 28%;
  }
}
.confetti-49 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 28%;
  opacity: 1.0854518522;
  transform: rotate(147.1189415211deg);
  -webkit-animation: drop-49 4.8749871415s 0.0759757329s infinite;
          animation: drop-49 4.8749871415s 0.0759757329s infinite;
}

@-webkit-keyframes drop-49 {
  100% {
    top: 110%;
    left: 42%;
  }
}

@keyframes drop-49 {
  100% {
    top: 110%;
    left: 42%;
  }
}
.confetti-50 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 35%;
  opacity: 0.8098575763;
  transform: rotate(270.7668831397deg);
  -webkit-animation: drop-50 4.5982047426s 0.4971509161s infinite;
          animation: drop-50 4.5982047426s 0.4971509161s infinite;
}

@-webkit-keyframes drop-50 {
  100% {
    top: 110%;
    left: 41%;
  }
}

@keyframes drop-50 {
  100% {
    top: 110%;
    left: 41%;
  }
}
.confetti-51 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 27%;
  opacity: 1.4794303401;
  transform: rotate(177.7998369894deg);
  -webkit-animation: drop-51 4.7623947002s 0.7629042259s infinite;
          animation: drop-51 4.7623947002s 0.7629042259s infinite;
}

@-webkit-keyframes drop-51 {
  100% {
    top: 110%;
    left: 34%;
  }
}

@keyframes drop-51 {
  100% {
    top: 110%;
    left: 34%;
  }
}
.confetti-52 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 22%;
  opacity: 0.9266013965;
  transform: rotate(316.2282470152deg);
  -webkit-animation: drop-52 4.2544246674s 0.497137531s infinite;
          animation: drop-52 4.2544246674s 0.497137531s infinite;
}

@-webkit-keyframes drop-52 {
  100% {
    top: 110%;
    left: 35%;
  }
}

@keyframes drop-52 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.confetti-53 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 30%;
  opacity: 1.3502459869;
  transform: rotate(295.217237975deg);
  -webkit-animation: drop-53 4.1055076582s 0.0832133955s infinite;
          animation: drop-53 4.1055076582s 0.0832133955s infinite;
}

@-webkit-keyframes drop-53 {
  100% {
    top: 110%;
    left: 45%;
  }
}

@keyframes drop-53 {
  100% {
    top: 110%;
    left: 45%;
  }
}
.confetti-54 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 98%;
  opacity: 1.494478141;
  transform: rotate(61.297528338deg);
  -webkit-animation: drop-54 4.5663688509s 0.8487894992s infinite;
          animation: drop-54 4.5663688509s 0.8487894992s infinite;
}

@-webkit-keyframes drop-54 {
  100% {
    top: 110%;
    left: 109%;
  }
}

@keyframes drop-54 {
  100% {
    top: 110%;
    left: 109%;
  }
}
.confetti-55 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 59%;
  opacity: 0.5772730308;
  transform: rotate(283.8435755029deg);
  -webkit-animation: drop-55 4.2488989707s 0.1055482539s infinite;
          animation: drop-55 4.2488989707s 0.1055482539s infinite;
}

@-webkit-keyframes drop-55 {
  100% {
    top: 110%;
    left: 65%;
  }
}

@keyframes drop-55 {
  100% {
    top: 110%;
    left: 65%;
  }
}
.confetti-56 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 91%;
  opacity: 1.2603801857;
  transform: rotate(194.5185138436deg);
  -webkit-animation: drop-56 4.5158271552s 0.7674033391s infinite;
          animation: drop-56 4.5158271552s 0.7674033391s infinite;
}

@-webkit-keyframes drop-56 {
  100% {
    top: 110%;
    left: 104%;
  }
}

@keyframes drop-56 {
  100% {
    top: 110%;
    left: 104%;
  }
}
.confetti-57 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 72%;
  opacity: 0.7495826373;
  transform: rotate(222.9381802797deg);
  -webkit-animation: drop-57 4.3610303388s 0.4705340539s infinite;
          animation: drop-57 4.3610303388s 0.4705340539s infinite;
}

@-webkit-keyframes drop-57 {
  100% {
    top: 110%;
    left: 85%;
  }
}

@keyframes drop-57 {
  100% {
    top: 110%;
    left: 85%;
  }
}
.confetti-58 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 14%;
  opacity: 1.4409444687;
  transform: rotate(59.0234480076deg);
  -webkit-animation: drop-58 4.7962830154s 0.8707588002s infinite;
          animation: drop-58 4.7962830154s 0.8707588002s infinite;
}

@-webkit-keyframes drop-58 {
  100% {
    top: 110%;
    left: 19%;
  }
}

@keyframes drop-58 {
  100% {
    top: 110%;
    left: 19%;
  }
}
.confetti-59 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 30%;
  opacity: 1.1438302526;
  transform: rotate(291.7519713929deg);
  -webkit-animation: drop-59 4.4700247142s 0.2466302551s infinite;
          animation: drop-59 4.4700247142s 0.2466302551s infinite;
}

@-webkit-keyframes drop-59 {
  100% {
    top: 110%;
    left: 42%;
  }
}

@keyframes drop-59 {
  100% {
    top: 110%;
    left: 42%;
  }
}
.confetti-60 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 67%;
  opacity: 1.4340308553;
  transform: rotate(328.3235265494deg);
  -webkit-animation: drop-60 4.4759461282s 0.90180486s infinite;
          animation: drop-60 4.4759461282s 0.90180486s infinite;
}

@-webkit-keyframes drop-60 {
  100% {
    top: 110%;
    left: 71%;
  }
}

@keyframes drop-60 {
  100% {
    top: 110%;
    left: 71%;
  }
}
.confetti-61 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 8%;
  opacity: 0.7535090162;
  transform: rotate(131.5589495857deg);
  -webkit-animation: drop-61 4.1111426359s 0.2680248047s infinite;
          animation: drop-61 4.1111426359s 0.2680248047s infinite;
}

@-webkit-keyframes drop-61 {
  100% {
    top: 110%;
    left: 12%;
  }
}

@keyframes drop-61 {
  100% {
    top: 110%;
    left: 12%;
  }
}
.confetti-62 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 56%;
  opacity: 1.3175114904;
  transform: rotate(308.9113889916deg);
  -webkit-animation: drop-62 4.5759691715s 0.0670849504s infinite;
          animation: drop-62 4.5759691715s 0.0670849504s infinite;
}

@-webkit-keyframes drop-62 {
  100% {
    top: 110%;
    left: 67%;
  }
}

@keyframes drop-62 {
  100% {
    top: 110%;
    left: 67%;
  }
}
.confetti-63 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 100%;
  opacity: 1.3118501057;
  transform: rotate(136.6405932908deg);
  -webkit-animation: drop-63 4.316912055s 0.8354948638s infinite;
          animation: drop-63 4.316912055s 0.8354948638s infinite;
}

@-webkit-keyframes drop-63 {
  100% {
    top: 110%;
    left: 107%;
  }
}

@keyframes drop-63 {
  100% {
    top: 110%;
    left: 107%;
  }
}
.confetti-64 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 39%;
  opacity: 0.5827772647;
  transform: rotate(121.0468502656deg);
  -webkit-animation: drop-64 4.9937956023s 0.3075398089s infinite;
          animation: drop-64 4.9937956023s 0.3075398089s infinite;
}

@-webkit-keyframes drop-64 {
  100% {
    top: 110%;
    left: 47%;
  }
}

@keyframes drop-64 {
  100% {
    top: 110%;
    left: 47%;
  }
}
.confetti-65 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 10%;
  opacity: 0.5302330477;
  transform: rotate(217.2661115415deg);
  -webkit-animation: drop-65 4.2310187398s 0.9562497537s infinite;
          animation: drop-65 4.2310187398s 0.9562497537s infinite;
}

@-webkit-keyframes drop-65 {
  100% {
    top: 110%;
    left: 24%;
  }
}

@keyframes drop-65 {
  100% {
    top: 110%;
    left: 24%;
  }
}
.confetti-66 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 57%;
  opacity: 0.8256150881;
  transform: rotate(202.7252905936deg);
  -webkit-animation: drop-66 4.6505476711s 0.8530615479s infinite;
          animation: drop-66 4.6505476711s 0.8530615479s infinite;
}

@-webkit-keyframes drop-66 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-66 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-67 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 58%;
  opacity: 1.1919798754;
  transform: rotate(302.5433547808deg);
  -webkit-animation: drop-67 4.4803701774s 0.778406312s infinite;
          animation: drop-67 4.4803701774s 0.778406312s infinite;
}

@-webkit-keyframes drop-67 {
  100% {
    top: 110%;
    left: 67%;
  }
}

@keyframes drop-67 {
  100% {
    top: 110%;
    left: 67%;
  }
}
.confetti-68 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 72%;
  opacity: 1.3237209218;
  transform: rotate(95.0639101899deg);
  -webkit-animation: drop-68 4.816660911s 0.855586563s infinite;
          animation: drop-68 4.816660911s 0.855586563s infinite;
}

@-webkit-keyframes drop-68 {
  100% {
    top: 110%;
    left: 81%;
  }
}

@keyframes drop-68 {
  100% {
    top: 110%;
    left: 81%;
  }
}
.confetti-69 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 68%;
  opacity: 1.0825494385;
  transform: rotate(312.7471532099deg);
  -webkit-animation: drop-69 4.4529947306s 0.9413246351s infinite;
          animation: drop-69 4.4529947306s 0.9413246351s infinite;
}

@-webkit-keyframes drop-69 {
  100% {
    top: 110%;
    left: 74%;
  }
}

@keyframes drop-69 {
  100% {
    top: 110%;
    left: 74%;
  }
}
.confetti-70 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 18%;
  opacity: 0.9967639722;
  transform: rotate(124.2740560073deg);
  -webkit-animation: drop-70 4.7628321518s 0.6357325584s infinite;
          animation: drop-70 4.7628321518s 0.6357325584s infinite;
}

@-webkit-keyframes drop-70 {
  100% {
    top: 110%;
    left: 21%;
  }
}

@keyframes drop-70 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.confetti-71 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 18%;
  opacity: 0.9553774558;
  transform: rotate(225.683752303deg);
  -webkit-animation: drop-71 4.1614540805s 0.087995842s infinite;
          animation: drop-71 4.1614540805s 0.087995842s infinite;
}

@-webkit-keyframes drop-71 {
  100% {
    top: 110%;
    left: 33%;
  }
}

@keyframes drop-71 {
  100% {
    top: 110%;
    left: 33%;
  }
}
.confetti-72 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 33%;
  opacity: 0.9300693918;
  transform: rotate(244.2448406776deg);
  -webkit-animation: drop-72 4.7206758837s 0.4335601903s infinite;
          animation: drop-72 4.7206758837s 0.4335601903s infinite;
}

@-webkit-keyframes drop-72 {
  100% {
    top: 110%;
    left: 43%;
  }
}

@keyframes drop-72 {
  100% {
    top: 110%;
    left: 43%;
  }
}
.confetti-73 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 15%;
  opacity: 0.7374816558;
  transform: rotate(153.3013293722deg);
  -webkit-animation: drop-73 4.8431244521s 0.707786329s infinite;
          animation: drop-73 4.8431244521s 0.707786329s infinite;
}

@-webkit-keyframes drop-73 {
  100% {
    top: 110%;
    left: 22%;
  }
}

@keyframes drop-73 {
  100% {
    top: 110%;
    left: 22%;
  }
}
.confetti-74 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 80%;
  opacity: 0.9038082968;
  transform: rotate(318.2725409179deg);
  -webkit-animation: drop-74 4.7242900414s 0.9811845571s infinite;
          animation: drop-74 4.7242900414s 0.9811845571s infinite;
}

@-webkit-keyframes drop-74 {
  100% {
    top: 110%;
    left: 95%;
  }
}

@keyframes drop-74 {
  100% {
    top: 110%;
    left: 95%;
  }
}
.confetti-75 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 81%;
  opacity: 1.2735481156;
  transform: rotate(86.492119636deg);
  -webkit-animation: drop-75 4.4421996524s 0.3394832734s infinite;
          animation: drop-75 4.4421996524s 0.3394832734s infinite;
}

@-webkit-keyframes drop-75 {
  100% {
    top: 110%;
    left: 86%;
  }
}

@keyframes drop-75 {
  100% {
    top: 110%;
    left: 86%;
  }
}
.confetti-76 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 76%;
  opacity: 0.679255131;
  transform: rotate(4.2645161161deg);
  -webkit-animation: drop-76 4.8368724551s 0.5188768291s infinite;
          animation: drop-76 4.8368724551s 0.5188768291s infinite;
}

@-webkit-keyframes drop-76 {
  100% {
    top: 110%;
    left: 89%;
  }
}

@keyframes drop-76 {
  100% {
    top: 110%;
    left: 89%;
  }
}
.confetti-77 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 74%;
  opacity: 0.9136342574;
  transform: rotate(22.9193623458deg);
  -webkit-animation: drop-77 4.7286215737s 0.4356260026s infinite;
          animation: drop-77 4.7286215737s 0.4356260026s infinite;
}

@-webkit-keyframes drop-77 {
  100% {
    top: 110%;
    left: 76%;
  }
}

@keyframes drop-77 {
  100% {
    top: 110%;
    left: 76%;
  }
}
.confetti-78 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 15%;
  opacity: 1.4478645324;
  transform: rotate(13.5932945483deg);
  -webkit-animation: drop-78 4.2949837076s 0.4544698254s infinite;
          animation: drop-78 4.2949837076s 0.4544698254s infinite;
}

@-webkit-keyframes drop-78 {
  100% {
    top: 110%;
    left: 18%;
  }
}

@keyframes drop-78 {
  100% {
    top: 110%;
    left: 18%;
  }
}
.confetti-79 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 39%;
  opacity: 1.0122229624;
  transform: rotate(103.9001362188deg);
  -webkit-animation: drop-79 4.2120594962s 0.7052231781s infinite;
          animation: drop-79 4.2120594962s 0.7052231781s infinite;
}

@-webkit-keyframes drop-79 {
  100% {
    top: 110%;
    left: 47%;
  }
}

@keyframes drop-79 {
  100% {
    top: 110%;
    left: 47%;
  }
}
.confetti-80 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 72%;
  opacity: 1.2903266699;
  transform: rotate(114.6219072105deg);
  -webkit-animation: drop-80 4.8338314661s 0.1998738907s infinite;
          animation: drop-80 4.8338314661s 0.1998738907s infinite;
}

@-webkit-keyframes drop-80 {
  100% {
    top: 110%;
    left: 80%;
  }
}

@keyframes drop-80 {
  100% {
    top: 110%;
    left: 80%;
  }
}
.confetti-81 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 92%;
  opacity: 0.6114536216;
  transform: rotate(6.6515205441deg);
  -webkit-animation: drop-81 4.89460945s 0.5315028921s infinite;
          animation: drop-81 4.89460945s 0.5315028921s infinite;
}

@-webkit-keyframes drop-81 {
  100% {
    top: 110%;
    left: 94%;
  }
}

@keyframes drop-81 {
  100% {
    top: 110%;
    left: 94%;
  }
}
.confetti-82 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 50%;
  opacity: 1.1295979963;
  transform: rotate(166.8070659488deg);
  -webkit-animation: drop-82 4.5860411235s 0.109497784s infinite;
          animation: drop-82 4.5860411235s 0.109497784s infinite;
}

@-webkit-keyframes drop-82 {
  100% {
    top: 110%;
    left: 60%;
  }
}

@keyframes drop-82 {
  100% {
    top: 110%;
    left: 60%;
  }
}
.confetti-83 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 39%;
  opacity: 0.8487155089;
  transform: rotate(12.1894099274deg);
  -webkit-animation: drop-83 4.1612772311s 0.3166493703s infinite;
          animation: drop-83 4.1612772311s 0.3166493703s infinite;
}

@-webkit-keyframes drop-83 {
  100% {
    top: 110%;
    left: 50%;
  }
}

@keyframes drop-83 {
  100% {
    top: 110%;
    left: 50%;
  }
}
.confetti-84 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 13%;
  opacity: 0.8869831267;
  transform: rotate(217.7783208897deg);
  -webkit-animation: drop-84 4.0242195129s 0.0947176837s infinite;
          animation: drop-84 4.0242195129s 0.0947176837s infinite;
}

@-webkit-keyframes drop-84 {
  100% {
    top: 110%;
    left: 21%;
  }
}

@keyframes drop-84 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.confetti-85 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 25%;
  opacity: 0.8470546194;
  transform: rotate(350.4480600564deg);
  -webkit-animation: drop-85 4.868014466s 0.6083111774s infinite;
          animation: drop-85 4.868014466s 0.6083111774s infinite;
}

@-webkit-keyframes drop-85 {
  100% {
    top: 110%;
    left: 33%;
  }
}

@keyframes drop-85 {
  100% {
    top: 110%;
    left: 33%;
  }
}
.confetti-86 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 9%;
  opacity: 1.1346528699;
  transform: rotate(94.5846536424deg);
  -webkit-animation: drop-86 4.0908931449s 0.5730191202s infinite;
          animation: drop-86 4.0908931449s 0.5730191202s infinite;
}

@-webkit-keyframes drop-86 {
  100% {
    top: 110%;
    left: 19%;
  }
}

@keyframes drop-86 {
  100% {
    top: 110%;
    left: 19%;
  }
}
.confetti-87 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 65%;
  opacity: 1.4841741363;
  transform: rotate(236.3344930702deg);
  -webkit-animation: drop-87 4.4114985739s 0.8537256847s infinite;
          animation: drop-87 4.4114985739s 0.8537256847s infinite;
}

@-webkit-keyframes drop-87 {
  100% {
    top: 110%;
    left: 76%;
  }
}

@keyframes drop-87 {
  100% {
    top: 110%;
    left: 76%;
  }
}
.confetti-88 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 15%;
  opacity: 0.9596484688;
  transform: rotate(47.211492854deg);
  -webkit-animation: drop-88 4.106770314s 0.3116437742s infinite;
          animation: drop-88 4.106770314s 0.3116437742s infinite;
}

@-webkit-keyframes drop-88 {
  100% {
    top: 110%;
    left: 27%;
  }
}

@keyframes drop-88 {
  100% {
    top: 110%;
    left: 27%;
  }
}
.confetti-89 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 18%;
  opacity: 0.9035879424;
  transform: rotate(65.7251321241deg);
  -webkit-animation: drop-89 4.107812443s 0.8835600424s infinite;
          animation: drop-89 4.107812443s 0.8835600424s infinite;
}

@-webkit-keyframes drop-89 {
  100% {
    top: 110%;
    left: 32%;
  }
}

@keyframes drop-89 {
  100% {
    top: 110%;
    left: 32%;
  }
}
.confetti-90 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 23%;
  opacity: 1.1769515913;
  transform: rotate(176.2816307072deg);
  -webkit-animation: drop-90 4.6625733942s 0.6432340669s infinite;
          animation: drop-90 4.6625733942s 0.6432340669s infinite;
}

@-webkit-keyframes drop-90 {
  100% {
    top: 110%;
    left: 28%;
  }
}

@keyframes drop-90 {
  100% {
    top: 110%;
    left: 28%;
  }
}
.confetti-91 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 100%;
  opacity: 0.8255753059;
  transform: rotate(242.3182906053deg);
  -webkit-animation: drop-91 4.0252059668s 0.3029346353s infinite;
          animation: drop-91 4.0252059668s 0.3029346353s infinite;
}

@-webkit-keyframes drop-91 {
  100% {
    top: 110%;
    left: 105%;
  }
}

@keyframes drop-91 {
  100% {
    top: 110%;
    left: 105%;
  }
}
.confetti-92 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 30%;
  opacity: 0.9348070917;
  transform: rotate(152.7383580198deg);
  -webkit-animation: drop-92 4.8610975592s 0.9245886041s infinite;
          animation: drop-92 4.8610975592s 0.9245886041s infinite;
}

@-webkit-keyframes drop-92 {
  100% {
    top: 110%;
    left: 42%;
  }
}

@keyframes drop-92 {
  100% {
    top: 110%;
    left: 42%;
  }
}
.confetti-93 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 2%;
  opacity: 1.2252451215;
  transform: rotate(311.0089703897deg);
  -webkit-animation: drop-93 4.8397237894s 0.9904521659s infinite;
          animation: drop-93 4.8397237894s 0.9904521659s infinite;
}

@-webkit-keyframes drop-93 {
  100% {
    top: 110%;
    left: 8%;
  }
}

@keyframes drop-93 {
  100% {
    top: 110%;
    left: 8%;
  }
}
.confetti-94 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 26%;
  opacity: 1.3412514037;
  transform: rotate(157.6505292317deg);
  -webkit-animation: drop-94 4.6309376987s 0.1609489884s infinite;
          animation: drop-94 4.6309376987s 0.1609489884s infinite;
}

@-webkit-keyframes drop-94 {
  100% {
    top: 110%;
    left: 39%;
  }
}

@keyframes drop-94 {
  100% {
    top: 110%;
    left: 39%;
  }
}
.confetti-95 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 11%;
  opacity: 1.2649701747;
  transform: rotate(198.2380044979deg);
  -webkit-animation: drop-95 4.0275681355s 0.8677190942s infinite;
          animation: drop-95 4.0275681355s 0.8677190942s infinite;
}

@-webkit-keyframes drop-95 {
  100% {
    top: 110%;
    left: 14%;
  }
}

@keyframes drop-95 {
  100% {
    top: 110%;
    left: 14%;
  }
}
.confetti-96 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 64%;
  opacity: 0.5268024001;
  transform: rotate(118.7492023554deg);
  -webkit-animation: drop-96 4.3004387758s 0.3285080551s infinite;
          animation: drop-96 4.3004387758s 0.3285080551s infinite;
}

@-webkit-keyframes drop-96 {
  100% {
    top: 110%;
    left: 77%;
  }
}

@keyframes drop-96 {
  100% {
    top: 110%;
    left: 77%;
  }
}
.confetti-97 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 70%;
  opacity: 0.5125315843;
  transform: rotate(207.8791670324deg);
  -webkit-animation: drop-97 4.6817623667s 0.1616026256s infinite;
          animation: drop-97 4.6817623667s 0.1616026256s infinite;
}

@-webkit-keyframes drop-97 {
  100% {
    top: 110%;
    left: 75%;
  }
}

@keyframes drop-97 {
  100% {
    top: 110%;
    left: 75%;
  }
}
.confetti-98 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 91%;
  opacity: 0.8664645804;
  transform: rotate(212.2176788779deg);
  -webkit-animation: drop-98 4.0130341986s 0.9437358846s infinite;
          animation: drop-98 4.0130341986s 0.9437358846s infinite;
}

@-webkit-keyframes drop-98 {
  100% {
    top: 110%;
    left: 94%;
  }
}

@keyframes drop-98 {
  100% {
    top: 110%;
    left: 94%;
  }
}
.confetti-99 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 54%;
  opacity: 1.2807395903;
  transform: rotate(213.0807151085deg);
  -webkit-animation: drop-99 4.776297878s 0.3388617074s infinite;
          animation: drop-99 4.776297878s 0.3388617074s infinite;
}

@-webkit-keyframes drop-99 {
  100% {
    top: 110%;
    left: 68%;
  }
}

@keyframes drop-99 {
  100% {
    top: 110%;
    left: 68%;
  }
}
.confetti-100 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 36%;
  opacity: 0.7050375747;
  transform: rotate(102.8701872997deg);
  -webkit-animation: drop-100 4.7240835149s 0.9224525822s infinite;
          animation: drop-100 4.7240835149s 0.9224525822s infinite;
}

@-webkit-keyframes drop-100 {
  100% {
    top: 110%;
    left: 37%;
  }
}

@keyframes drop-100 {
  100% {
    top: 110%;
    left: 37%;
  }
}
.confetti-101 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 79%;
  opacity: 0.5873762369;
  transform: rotate(109.5746155977deg);
  -webkit-animation: drop-101 4.2442101711s 0.5479437109s infinite;
          animation: drop-101 4.2442101711s 0.5479437109s infinite;
}

@-webkit-keyframes drop-101 {
  100% {
    top: 110%;
    left: 93%;
  }
}

@keyframes drop-101 {
  100% {
    top: 110%;
    left: 93%;
  }
}
.confetti-102 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 27%;
  opacity: 1.371047769;
  transform: rotate(114.1204011232deg);
  -webkit-animation: drop-102 4.8620895536s 0.2795892702s infinite;
          animation: drop-102 4.8620895536s 0.2795892702s infinite;
}

@-webkit-keyframes drop-102 {
  100% {
    top: 110%;
    left: 29%;
  }
}

@keyframes drop-102 {
  100% {
    top: 110%;
    left: 29%;
  }
}
.confetti-103 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 72%;
  opacity: 0.8617054108;
  transform: rotate(136.4677838755deg);
  -webkit-animation: drop-103 4.703555648s 0.4653520147s infinite;
          animation: drop-103 4.703555648s 0.4653520147s infinite;
}

@-webkit-keyframes drop-103 {
  100% {
    top: 110%;
    left: 75%;
  }
}

@keyframes drop-103 {
  100% {
    top: 110%;
    left: 75%;
  }
}
.confetti-104 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 31%;
  opacity: 1.3376710736;
  transform: rotate(344.9840228756deg);
  -webkit-animation: drop-104 4.4063586347s 0.3557121485s infinite;
          animation: drop-104 4.4063586347s 0.3557121485s infinite;
}

@-webkit-keyframes drop-104 {
  100% {
    top: 110%;
    left: 40%;
  }
}

@keyframes drop-104 {
  100% {
    top: 110%;
    left: 40%;
  }
}
.confetti-105 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 95%;
  opacity: 1.0582372837;
  transform: rotate(162.5329437612deg);
  -webkit-animation: drop-105 4.6115159252s 0.412726465s infinite;
          animation: drop-105 4.6115159252s 0.412726465s infinite;
}

@-webkit-keyframes drop-105 {
  100% {
    top: 110%;
    left: 98%;
  }
}

@keyframes drop-105 {
  100% {
    top: 110%;
    left: 98%;
  }
}
.confetti-106 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 100%;
  opacity: 1.2229202;
  transform: rotate(36.6464418995deg);
  -webkit-animation: drop-106 4.0035973359s 0.8277903539s infinite;
          animation: drop-106 4.0035973359s 0.8277903539s infinite;
}

@-webkit-keyframes drop-106 {
  100% {
    top: 110%;
    left: 113%;
  }
}

@keyframes drop-106 {
  100% {
    top: 110%;
    left: 113%;
  }
}
.confetti-107 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 15%;
  opacity: 0.6000624856;
  transform: rotate(326.2366010626deg);
  -webkit-animation: drop-107 4.4732798968s 0.5862230103s infinite;
          animation: drop-107 4.4732798968s 0.5862230103s infinite;
}

@-webkit-keyframes drop-107 {
  100% {
    top: 110%;
    left: 29%;
  }
}

@keyframes drop-107 {
  100% {
    top: 110%;
    left: 29%;
  }
}
.confetti-108 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 88%;
  opacity: 1.1070030961;
  transform: rotate(303.1891749798deg);
  -webkit-animation: drop-108 4.5366881814s 0.8421966477s infinite;
          animation: drop-108 4.5366881814s 0.8421966477s infinite;
}

@-webkit-keyframes drop-108 {
  100% {
    top: 110%;
    left: 102%;
  }
}

@keyframes drop-108 {
  100% {
    top: 110%;
    left: 102%;
  }
}
.confetti-109 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 87%;
  opacity: 0.6996677737;
  transform: rotate(244.2798663565deg);
  -webkit-animation: drop-109 4.3982208946s 0.2080121026s infinite;
          animation: drop-109 4.3982208946s 0.2080121026s infinite;
}

@-webkit-keyframes drop-109 {
  100% {
    top: 110%;
    left: 100%;
  }
}

@keyframes drop-109 {
  100% {
    top: 110%;
    left: 100%;
  }
}
.confetti-110 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 71%;
  opacity: 0.859399325;
  transform: rotate(272.5225377849deg);
  -webkit-animation: drop-110 4.4454411193s 0.8713413984s infinite;
          animation: drop-110 4.4454411193s 0.8713413984s infinite;
}

@-webkit-keyframes drop-110 {
  100% {
    top: 110%;
    left: 80%;
  }
}

@keyframes drop-110 {
  100% {
    top: 110%;
    left: 80%;
  }
}
.confetti-111 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 47%;
  opacity: 1.1436705945;
  transform: rotate(277.8810853098deg);
  -webkit-animation: drop-111 4.0579950137s 0.0529135199s infinite;
          animation: drop-111 4.0579950137s 0.0529135199s infinite;
}

@-webkit-keyframes drop-111 {
  100% {
    top: 110%;
    left: 58%;
  }
}

@keyframes drop-111 {
  100% {
    top: 110%;
    left: 58%;
  }
}
.confetti-112 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 90%;
  opacity: 1.4375475123;
  transform: rotate(158.8648899604deg);
  -webkit-animation: drop-112 4.8248579892s 0.4205981235s infinite;
          animation: drop-112 4.8248579892s 0.4205981235s infinite;
}

@-webkit-keyframes drop-112 {
  100% {
    top: 110%;
    left: 97%;
  }
}

@keyframes drop-112 {
  100% {
    top: 110%;
    left: 97%;
  }
}
.confetti-113 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 88%;
  opacity: 1.3991643768;
  transform: rotate(197.4221991681deg);
  -webkit-animation: drop-113 4.4551836396s 0.0942773674s infinite;
          animation: drop-113 4.4551836396s 0.0942773674s infinite;
}

@-webkit-keyframes drop-113 {
  100% {
    top: 110%;
    left: 95%;
  }
}

@keyframes drop-113 {
  100% {
    top: 110%;
    left: 95%;
  }
}
.confetti-114 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 60%;
  opacity: 0.5426456742;
  transform: rotate(11.5470026489deg);
  -webkit-animation: drop-114 4.2804789865s 0.8102417591s infinite;
          animation: drop-114 4.2804789865s 0.8102417591s infinite;
}

@-webkit-keyframes drop-114 {
  100% {
    top: 110%;
    left: 67%;
  }
}

@keyframes drop-114 {
  100% {
    top: 110%;
    left: 67%;
  }
}
.confetti-115 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 82%;
  opacity: 0.6109960123;
  transform: rotate(216.3715315135deg);
  -webkit-animation: drop-115 4.0002624419s 0.8683377837s infinite;
          animation: drop-115 4.0002624419s 0.8683377837s infinite;
}

@-webkit-keyframes drop-115 {
  100% {
    top: 110%;
    left: 85%;
  }
}

@keyframes drop-115 {
  100% {
    top: 110%;
    left: 85%;
  }
}
.confetti-116 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 72%;
  opacity: 0.6104293182;
  transform: rotate(98.5850407782deg);
  -webkit-animation: drop-116 4.5423004406s 0.25264675s infinite;
          animation: drop-116 4.5423004406s 0.25264675s infinite;
}

@-webkit-keyframes drop-116 {
  100% {
    top: 110%;
    left: 81%;
  }
}

@keyframes drop-116 {
  100% {
    top: 110%;
    left: 81%;
  }
}
.confetti-117 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 8%;
  opacity: 1.1337514138;
  transform: rotate(278.421991479deg);
  -webkit-animation: drop-117 4.7239200359s 0.9870971645s infinite;
          animation: drop-117 4.7239200359s 0.9870971645s infinite;
}

@-webkit-keyframes drop-117 {
  100% {
    top: 110%;
    left: 11%;
  }
}

@keyframes drop-117 {
  100% {
    top: 110%;
    left: 11%;
  }
}
.confetti-118 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 85%;
  opacity: 0.7118274467;
  transform: rotate(326.1990684009deg);
  -webkit-animation: drop-118 4.9564596587s 0.7968544778s infinite;
          animation: drop-118 4.9564596587s 0.7968544778s infinite;
}

@-webkit-keyframes drop-118 {
  100% {
    top: 110%;
    left: 86%;
  }
}

@keyframes drop-118 {
  100% {
    top: 110%;
    left: 86%;
  }
}
.confetti-119 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 35%;
  opacity: 1.4655796313;
  transform: rotate(254.7765459131deg);
  -webkit-animation: drop-119 4.8290850623s 0.7622202198s infinite;
          animation: drop-119 4.8290850623s 0.7622202198s infinite;
}

@-webkit-keyframes drop-119 {
  100% {
    top: 110%;
    left: 47%;
  }
}

@keyframes drop-119 {
  100% {
    top: 110%;
    left: 47%;
  }
}
.confetti-120 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 93%;
  opacity: 1.4538206904;
  transform: rotate(334.9706498611deg);
  -webkit-animation: drop-120 4.2799660404s 0.9406199871s infinite;
          animation: drop-120 4.2799660404s 0.9406199871s infinite;
}

@-webkit-keyframes drop-120 {
  100% {
    top: 110%;
    left: 98%;
  }
}

@keyframes drop-120 {
  100% {
    top: 110%;
    left: 98%;
  }
}
.confetti-121 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 43%;
  opacity: 1.253191186;
  transform: rotate(195.4332837667deg);
  -webkit-animation: drop-121 4.8595867856s 0.6539719038s infinite;
          animation: drop-121 4.8595867856s 0.6539719038s infinite;
}

@-webkit-keyframes drop-121 {
  100% {
    top: 110%;
    left: 45%;
  }
}

@keyframes drop-121 {
  100% {
    top: 110%;
    left: 45%;
  }
}
.confetti-122 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 76%;
  opacity: 0.904710073;
  transform: rotate(355.3569372108deg);
  -webkit-animation: drop-122 4.3456443266s 0.056844433s infinite;
          animation: drop-122 4.3456443266s 0.056844433s infinite;
}

@-webkit-keyframes drop-122 {
  100% {
    top: 110%;
    left: 83%;
  }
}

@keyframes drop-122 {
  100% {
    top: 110%;
    left: 83%;
  }
}
.confetti-123 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 74%;
  opacity: 1.2096226035;
  transform: rotate(110.4689201766deg);
  -webkit-animation: drop-123 4.2416313621s 0.3200166387s infinite;
          animation: drop-123 4.2416313621s 0.3200166387s infinite;
}

@-webkit-keyframes drop-123 {
  100% {
    top: 110%;
    left: 79%;
  }
}

@keyframes drop-123 {
  100% {
    top: 110%;
    left: 79%;
  }
}
.confetti-124 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 30%;
  opacity: 1.0346784317;
  transform: rotate(331.3097591376deg);
  -webkit-animation: drop-124 4.8155061844s 0.2760006776s infinite;
          animation: drop-124 4.8155061844s 0.2760006776s infinite;
}

@-webkit-keyframes drop-124 {
  100% {
    top: 110%;
    left: 36%;
  }
}

@keyframes drop-124 {
  100% {
    top: 110%;
    left: 36%;
  }
}
.confetti-125 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 50%;
  opacity: 0.8760459904;
  transform: rotate(340.4054064719deg);
  -webkit-animation: drop-125 4.2464774462s 0.6052354984s infinite;
          animation: drop-125 4.2464774462s 0.6052354984s infinite;
}

@-webkit-keyframes drop-125 {
  100% {
    top: 110%;
    left: 55%;
  }
}

@keyframes drop-125 {
  100% {
    top: 110%;
    left: 55%;
  }
}
.confetti-126 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 28%;
  opacity: 1.0468496612;
  transform: rotate(133.0811332208deg);
  -webkit-animation: drop-126 4.125571246s 0.1592957183s infinite;
          animation: drop-126 4.125571246s 0.1592957183s infinite;
}

@-webkit-keyframes drop-126 {
  100% {
    top: 110%;
    left: 33%;
  }
}

@keyframes drop-126 {
  100% {
    top: 110%;
    left: 33%;
  }
}
.confetti-127 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 92%;
  opacity: 0.6835447624;
  transform: rotate(230.7850708146deg);
  -webkit-animation: drop-127 4.5070858054s 0.5028255756s infinite;
          animation: drop-127 4.5070858054s 0.5028255756s infinite;
}

@-webkit-keyframes drop-127 {
  100% {
    top: 110%;
    left: 97%;
  }
}

@keyframes drop-127 {
  100% {
    top: 110%;
    left: 97%;
  }
}
.confetti-128 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 72%;
  opacity: 0.8603665713;
  transform: rotate(168.9691200997deg);
  -webkit-animation: drop-128 4.9785559023s 0.1155217435s infinite;
          animation: drop-128 4.9785559023s 0.1155217435s infinite;
}

@-webkit-keyframes drop-128 {
  100% {
    top: 110%;
    left: 79%;
  }
}

@keyframes drop-128 {
  100% {
    top: 110%;
    left: 79%;
  }
}
.confetti-129 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 77%;
  opacity: 1.2781561469;
  transform: rotate(34.8673654728deg);
  -webkit-animation: drop-129 4.6977901968s 0.6004572156s infinite;
          animation: drop-129 4.6977901968s 0.6004572156s infinite;
}

@-webkit-keyframes drop-129 {
  100% {
    top: 110%;
    left: 81%;
  }
}

@keyframes drop-129 {
  100% {
    top: 110%;
    left: 81%;
  }
}
.confetti-130 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 76%;
  opacity: 0.7915281576;
  transform: rotate(289.6036833913deg);
  -webkit-animation: drop-130 4.0438634811s 0.511981639s infinite;
          animation: drop-130 4.0438634811s 0.511981639s infinite;
}

@-webkit-keyframes drop-130 {
  100% {
    top: 110%;
    left: 83%;
  }
}

@keyframes drop-130 {
  100% {
    top: 110%;
    left: 83%;
  }
}
.confetti-131 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 73%;
  opacity: 0.6536993436;
  transform: rotate(236.0872245226deg);
  -webkit-animation: drop-131 4.102394772s 0.8677552212s infinite;
          animation: drop-131 4.102394772s 0.8677552212s infinite;
}

@-webkit-keyframes drop-131 {
  100% {
    top: 110%;
    left: 79%;
  }
}

@keyframes drop-131 {
  100% {
    top: 110%;
    left: 79%;
  }
}
.confetti-132 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 82%;
  opacity: 0.5533984849;
  transform: rotate(6.5362766772deg);
  -webkit-animation: drop-132 4.8235796522s 0.8026095755s infinite;
          animation: drop-132 4.8235796522s 0.8026095755s infinite;
}

@-webkit-keyframes drop-132 {
  100% {
    top: 110%;
    left: 89%;
  }
}

@keyframes drop-132 {
  100% {
    top: 110%;
    left: 89%;
  }
}
.confetti-133 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 85%;
  opacity: 0.8314874241;
  transform: rotate(328.7840839024deg);
  -webkit-animation: drop-133 4.3754836158s 0.3109513252s infinite;
          animation: drop-133 4.3754836158s 0.3109513252s infinite;
}

@-webkit-keyframes drop-133 {
  100% {
    top: 110%;
    left: 98%;
  }
}

@keyframes drop-133 {
  100% {
    top: 110%;
    left: 98%;
  }
}
.confetti-134 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 86%;
  opacity: 0.7932504214;
  transform: rotate(316.1527520961deg);
  -webkit-animation: drop-134 4.2044373302s 0.6874567845s infinite;
          animation: drop-134 4.2044373302s 0.6874567845s infinite;
}

@-webkit-keyframes drop-134 {
  100% {
    top: 110%;
    left: 97%;
  }
}

@keyframes drop-134 {
  100% {
    top: 110%;
    left: 97%;
  }
}
.confetti-135 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 71%;
  opacity: 1.373367962;
  transform: rotate(345.9167218033deg);
  -webkit-animation: drop-135 4.5737623836s 0.4474017721s infinite;
          animation: drop-135 4.5737623836s 0.4474017721s infinite;
}

@-webkit-keyframes drop-135 {
  100% {
    top: 110%;
    left: 84%;
  }
}

@keyframes drop-135 {
  100% {
    top: 110%;
    left: 84%;
  }
}
.confetti-136 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 64%;
  opacity: 1.3067946522;
  transform: rotate(197.0268506513deg);
  -webkit-animation: drop-136 4.6009784766s 0.4154644607s infinite;
          animation: drop-136 4.6009784766s 0.4154644607s infinite;
}

@-webkit-keyframes drop-136 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-136 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-137 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 88%;
  opacity: 0.9093818596;
  transform: rotate(63.9751520382deg);
  -webkit-animation: drop-137 4.5371417758s 0.0631787427s infinite;
          animation: drop-137 4.5371417758s 0.0631787427s infinite;
}

@-webkit-keyframes drop-137 {
  100% {
    top: 110%;
    left: 95%;
  }
}

@keyframes drop-137 {
  100% {
    top: 110%;
    left: 95%;
  }
}
.confetti-138 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 20%;
  opacity: 0.7407655614;
  transform: rotate(89.9865834186deg);
  -webkit-animation: drop-138 4.9547783091s 0.2040672301s infinite;
          animation: drop-138 4.9547783091s 0.2040672301s infinite;
}

@-webkit-keyframes drop-138 {
  100% {
    top: 110%;
    left: 28%;
  }
}

@keyframes drop-138 {
  100% {
    top: 110%;
    left: 28%;
  }
}
.confetti-139 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 27%;
  opacity: 1.1538539468;
  transform: rotate(226.0216225998deg);
  -webkit-animation: drop-139 4.5068520932s 0.8935683694s infinite;
          animation: drop-139 4.5068520932s 0.8935683694s infinite;
}

@-webkit-keyframes drop-139 {
  100% {
    top: 110%;
    left: 41%;
  }
}

@keyframes drop-139 {
  100% {
    top: 110%;
    left: 41%;
  }
}
.confetti-140 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 38%;
  opacity: 1.0546438835;
  transform: rotate(215.0548425486deg);
  -webkit-animation: drop-140 4.1259956608s 0.2518002954s infinite;
          animation: drop-140 4.1259956608s 0.2518002954s infinite;
}

@-webkit-keyframes drop-140 {
  100% {
    top: 110%;
    left: 48%;
  }
}

@keyframes drop-140 {
  100% {
    top: 110%;
    left: 48%;
  }
}
.confetti-141 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 20%;
  opacity: 0.876750628;
  transform: rotate(44.7798664199deg);
  -webkit-animation: drop-141 4.7612870621s 0.018641931s infinite;
          animation: drop-141 4.7612870621s 0.018641931s infinite;
}

@-webkit-keyframes drop-141 {
  100% {
    top: 110%;
    left: 21%;
  }
}

@keyframes drop-141 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.confetti-142 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 81%;
  opacity: 0.9724947602;
  transform: rotate(56.6131729986deg);
  -webkit-animation: drop-142 4.7187307958s 0.9410660785s infinite;
          animation: drop-142 4.7187307958s 0.9410660785s infinite;
}

@-webkit-keyframes drop-142 {
  100% {
    top: 110%;
    left: 86%;
  }
}

@keyframes drop-142 {
  100% {
    top: 110%;
    left: 86%;
  }
}
.confetti-143 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 31%;
  opacity: 0.837617893;
  transform: rotate(43.6225072941deg);
  -webkit-animation: drop-143 4.9039144134s 0.1337507788s infinite;
          animation: drop-143 4.9039144134s 0.1337507788s infinite;
}

@-webkit-keyframes drop-143 {
  100% {
    top: 110%;
    left: 34%;
  }
}

@keyframes drop-143 {
  100% {
    top: 110%;
    left: 34%;
  }
}
.confetti-144 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 92%;
  opacity: 0.5657435502;
  transform: rotate(140.0990065985deg);
  -webkit-animation: drop-144 4.2666230459s 0.3419956492s infinite;
          animation: drop-144 4.2666230459s 0.3419956492s infinite;
}

@-webkit-keyframes drop-144 {
  100% {
    top: 110%;
    left: 106%;
  }
}

@keyframes drop-144 {
  100% {
    top: 110%;
    left: 106%;
  }
}
.confetti-145 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 73%;
  opacity: 1.2736944523;
  transform: rotate(120.2899279448deg);
  -webkit-animation: drop-145 4.5080895506s 0.2656285258s infinite;
          animation: drop-145 4.5080895506s 0.2656285258s infinite;
}

@-webkit-keyframes drop-145 {
  100% {
    top: 110%;
    left: 74%;
  }
}

@keyframes drop-145 {
  100% {
    top: 110%;
    left: 74%;
  }
}
.confetti-146 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 87%;
  opacity: 0.8385404337;
  transform: rotate(233.4100331073deg);
  -webkit-animation: drop-146 4.5499848722s 0.8724096969s infinite;
          animation: drop-146 4.5499848722s 0.8724096969s infinite;
}

@-webkit-keyframes drop-146 {
  100% {
    top: 110%;
    left: 91%;
  }
}

@keyframes drop-146 {
  100% {
    top: 110%;
    left: 91%;
  }
}
.confetti-147 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 48%;
  opacity: 1.1802437786;
  transform: rotate(236.3704094225deg);
  -webkit-animation: drop-147 4.0416415988s 0.6925944183s infinite;
          animation: drop-147 4.0416415988s 0.6925944183s infinite;
}

@-webkit-keyframes drop-147 {
  100% {
    top: 110%;
    left: 63%;
  }
}

@keyframes drop-147 {
  100% {
    top: 110%;
    left: 63%;
  }
}
.confetti-148 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 86%;
  opacity: 1.1343218045;
  transform: rotate(31.1667270365deg);
  -webkit-animation: drop-148 4.518456005s 0.2255140148s infinite;
          animation: drop-148 4.518456005s 0.2255140148s infinite;
}

@-webkit-keyframes drop-148 {
  100% {
    top: 110%;
    left: 88%;
  }
}

@keyframes drop-148 {
  100% {
    top: 110%;
    left: 88%;
  }
}
.confetti-149 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 24%;
  opacity: 1.2342660294;
  transform: rotate(17.9111997181deg);
  -webkit-animation: drop-149 4.2507794571s 0.8409165218s infinite;
          animation: drop-149 4.2507794571s 0.8409165218s infinite;
}

@-webkit-keyframes drop-149 {
  100% {
    top: 110%;
    left: 31%;
  }
}

@keyframes drop-149 {
  100% {
    top: 110%;
    left: 31%;
  }
}
.confetti-150 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 37%;
  opacity: 0.9667256884;
  transform: rotate(153.5734244558deg);
  -webkit-animation: drop-150 4.8461856557s 0.887001674s infinite;
          animation: drop-150 4.8461856557s 0.887001674s infinite;
}

@-webkit-keyframes drop-150 {
  100% {
    top: 110%;
    left: 42%;
  }
}

@keyframes drop-151 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-152 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-153 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-154 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-155 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-156 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-157 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-158 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-159 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-160 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-161 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-162 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-163 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-164 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-165 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-167 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-168 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-169 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-170 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-171 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-172 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-173 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-174 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-175 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-176 {
  100% {
    top: 110%;
    left: 42%;
  }
}
@keyframes drop-177 {
  100% {
    top: 110%;
    left: 42%;
  }
}